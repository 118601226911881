import Vue from 'vue';
import Vuex from 'vuex';

// import { createStore } from 'vuex';
import http from '@/services/http';

import accounts from '@/store/accounts';
import subscriptions from '@/store/subscriptions';
import marketplaceEpochs from '@/store/marketplaceEpochs';
import subscriptionMarketplaceOrders from '@/store/subscriptionMarketplaceOrders';
import rateTiers from '@/store/rateTiers';
import roles from '@/store/roles';
import subscriptionTypes from '@/store/subscriptionTypes';
import loadBalancers from '@/store/loadBalancers';
import sessions from '@/store/session';
import metrics from '@/store/metrics';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    accounts,
    subscriptions,
    subscriptionMarketplaceOrders,
    marketplaceEpochs,
    rateTiers,
    roles,
    subscriptionTypes,
    loadBalancers,
    sessions,
    metrics
  },

  state: {
    searchQuery: null,
    systemFeatures: null,
  },

  getters: {
    searchQuery(state) {
      return state.searchQuery;
    },

    subscriptionTypes(state) {
      return state.subscriptionTypes;
    },

    loadBalancers(state) {
      return state.loadBalancers;
    },
  },

  // actions do not have to be synchronous
  actions: {
    async getSystemFeatures({ commit }) {
      try {
        const response = await http.get('system_features');
        commit('SET_SYSTEM_FEATURES', response.data.system_features);
      }
      catch (error) {
        throw error;
      }
    }
  },

  mutations: {
    SET_SEARCH_QUERY(state, searchQuery) {
      state.searchQuery = searchQuery;
    },

    SET_SYSTEM_FEATURES(state, systemFeatures) {
      state.systemFeatures = systemFeatures;
    }
  }
});

export default store;
