import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    subscriptions: []
  },

  getters: {
    // mySubscriptions(state) {
    //   return state.mySubscriptions;
    // },
  },

  mutations: {
    SET_SUBSCRIPTIONS(state, subscriptions) {
      state.subscriptions = subscriptions;
    },

    TOGGLE_SUBSCRIPTION_ACTIVATION(state, subscription) {
      subscription.is_active = !subscription.is_active;
    },

    UPDATE_SUBSCRIPTION_ACTIVATION(state, subscription, isActive) {
      subscription.is_active = isActive;
    },

    TOGGLE_ACCOUNT_ACTIVATION(state, subscription) {
      subscription.account.is_active = !subscription.account.is_active;
    },

    RESET_STATE(state) {
      state.subscriptions = [];
    },
  },

  actions: {
    // async getMySubscriptions({ commit }) {
    //   try {
    //     const response = await http.get('subscriptions');

    //     commit('SET_MY_SUBSCRIPTIONS', response.data.subscriptions);
    //   }

    //   catch (error) {
    //     console.log(error);
    //   }
    // },

    async fetchSubscriptions({ commit }, subscriptionsParams) {
      try {
        const response = await http.get(`subscriptions`, {
          params: subscriptionsParams
        });

        commit('SET_SUBSCRIPTIONS', response.data.subscriptions);

        return response.data.subscriptions;
      }

      catch (error) {
        console.log(error);
      }
    },

    async toggleSubscriptionActivation({ commit, state }, subscriptionsParams) {
      const foundSubscription = state.subscriptions.find(sub =>
        sub.uuid === subscriptionsParams.subscriptionUuid
      )

      if (foundSubscription) {
        commit('TOGGLE_SUBSCRIPTION_ACTIVATION', foundSubscription);
      }
    },

    async toggleSubscriptionsActivation({ commit, state }, accountParams) {
      const matchingSubscriptions = state.subscriptions.filter(sub =>
        sub.account.uuid === accountParams.accountUuid
      )

      matchingSubscriptions.forEach(subscription => {
        commit('TOGGLE_ACCOUNT_ACTIVATION', subscription);
      });
    },
  }
}
