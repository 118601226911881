<template>
  <div class="p-3 container-xl">
    <div class="card mb-3">
      <div class="card-header">
        <h2>Rate Tiers</h2>
      </div>
      <div class="card-body" id="rate-tiers">
        <rate-tiers-table />

        <h4 class="mt-4">Add rate tier</h4>
        <b-form v-on:submit.prevent="createRateTier">
          <b-form-group>
            <label for="rate_tier_name">Name</label>

            <b-form-input v-model="rateTierForm.name" :state="rateTierNameState" />
            <b-form-invalid-feedback :state="rateTierNameState">
              {{ rateTierNameErrors }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="form-group mt-3">
            <input type="submit" value="Save" class="btn btn-primary" />
          </div>
        </b-form>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-header d-flex justify-content-between">
        <h2>Subscription Types</h2>

        <div class="text-center">
          <b-button
            v-b-modal="'modal-create-new-subscription-type'"
            class="btn btn-primary"
          >
            New
          </b-button>
        </div>

        <subscription-type-modal />
      </div>

      <div class="card-body" id="subscription-types">
        <subscription-types-table />
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-header">
        <h2>Load Balancers</h2>
      </div>

      <div class="card-body" id="load-balancers">
        <load-balancers-table />

        <h4 class="mt-4">Add load balancer</h4>
        <load-balancer-form />
      </div>
    </div>
  </div>
</template>

<script>
import subscriptionTypeModal from '../../globals/subscription-type-modal.vue';
import rateTiersTable from './rate-tiers-table';

export default {
  components: { subscriptionTypeModal, rateTiersTable },

  data() {
    return {
      rateTierForm: {
        name: null,
        errors: {},
      },
    };
  },

  computed: {
    rateTierNameState() {
      if (this.rateTierForm.errors['name']) {
        return false;
      }
    },

    rateTierNameErrors() {
      const errors = this.rateTierForm.errors['name'];
      if (errors) {
        return errors.join(', ');
      }
    },
  },

  methods: {
    async createRateTier() {
      try {
        await this.$store.dispatch('rateTiers/createRateTier', {
          rateTier: {
            name: this.rateTierForm.name,
          },
        });

        this.$bvToast.toast(`You successfully added a new rate tier`, {
          title: 'Created',
          variant: 'default',
          autoHideDelay: 500,
        });

        this.rateTierForm = {
          name: null,
          errors: {},
        };
      } catch (error) {
        console.log(error);

        if (error.response) {
          const errorResponse = error.response.data;
          this.rateTierForm.errors = errorResponse.errors;
        } else {
          this.$bvToast.toast(`There was an error submitting your request`, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 500,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-footer {
  border-top: 0;
}
.card-header {
  border-radius: 20px;
}
</style>

<style lang="scss">
.b-toast-default .toast {
  background-color: #5c66c0;
  border-color: #5c66c0;
  color: #fff;
  opacity: 0.9;

  .toast-header {
    color: #fff;
    background-color: #858dd0;
    border-bottom-color: #858dd0;
  }
}
</style>
