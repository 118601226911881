import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    marketplaceEpochs: [],
    loading: false,
    hasFetched: false,
  },

  getters: {
    marketplaceEpochs: state => state.marketplaceEpochs,
    isLoading: state => state.loading,

    currentMarketplaceEpoch: state => {
      return state.marketplaceEpochs.find(me => me.epoch_info && me.epoch_info.is_current_epoch);
    },

    currentAndFutureMarketplaceEpochs(state, getters) {
      const currentEpochNumber = getters.currentMarketplaceEpoch.epoch;
      const currentEpochIndex = state.marketplaceEpochs.findIndex(me => me.epoch === currentEpochNumber);
      return state.marketplaceEpochs.slice(currentEpochIndex);
    },

    futureEpochsOpenForOrders: state => {
      return state.marketplaceEpochs.filter(me => me.orders_closed_at === null);
    },

    soonestEpochOpenForOrders(state, getters) {
      return getters.futureEpochsOpenForOrders.sort((a, b) => a.orders_closed_at - b.orders_closed_at)[0];
    },

    lastMarketplaceEpochOpenForOrders(_state, getters) {
      return getters.futureEpochsOpenForOrders.at(-1);
    }
  },

  mutations: {
    FETCH_START(state) {
      state.loading = true;
      state.error = null;
    },

    SET_MARKETPLACE_EPOCHS(state, epochs) {
      state.marketplaceEpochs = epochs;
      state.loading = false;
      state.hasFetched = true;
    },

    RESET_STATE(state) {
      state.marketplaceEpochs = [];
      state.loading = false;
      state.hasFetched = false;
    }
  },

  actions: {
    fetchMarketplaceEpochs({ commit, state }, { forceRefresh = false } = {}) {
      if (state.hasFetched && !forceRefresh) {
        return Promise.resolve();
      }

      commit('FETCH_START');
      return http
        .get('/marketplace_epochs')
        .then(response => {
          commit('SET_MARKETPLACE_EPOCHS', response.data.marketplace_epochs);
        })
    }
  },
}
