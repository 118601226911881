import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    roles: [],
    isFetching: false
  },

  mutations: {
    SET_ROLES(state, roles) {
      state.roles = roles;
    },

    SET_FETCHING(state, isFetching) {
      state.isFetching = isFetching;
    },

    RESET_STATE(state) {
      state.roles = [];
      state.lastFetchedAt = null;
      state.isFetching = false;
    }
  },

  actions: {
    async fetchRoles({ commit, state }, { force = false } = {}) {
      // Return cached roles if available and not forced
      if (!force && state.roles.length > 0) {
        return state.roles;
      }

      // Prevent multiple simultaneous fetches
      if (state.isFetching) {
        return state.roles;
      }

      try {
        commit('SET_FETCHING', true);
        const response = await http.get('roles');
        commit('SET_ROLES', response.data.roles);
        return response.data.roles;
      } finally {
        commit('SET_FETCHING', false);
      }
    }
  }
}
