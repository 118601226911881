<template>
  <div>
    <div class="mb-3">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <b-input
              v-model="searchUsersTerm"
              placeholder="Search by username"
              autocomplete="off"
              type="search"
            />
          </div>
        </div>

        <div class="col-sm-6">
          <b-form-group>
            <v-select
              v-model="searchRole"
              :options="rolesDropdownOptions"
              placeholder="Filter by role"
              :clearable="true"
            />
          </b-form-group>
        </div>

        <div class="col-sm-6">
          <b-form-group>
            <v-select
              v-model="statusFilter"
              :options="statusesDropdownOptions"
              placeholder="Filter by status"
              :clearable="true"
            />
          </b-form-group>
        </div>
      </div>
    </div>

    <div v-if="isLoading">
      <div class="text-center min-height mb-3" :style="`height: ${loadingHeight}px`">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </div>
    <div v-else class="table-responsive scrollbar" ref="usersTable">
      <table class="table table-hover table-sm">
        <thead class="border-bottom">
          <tr>
            <th scope="col">Username</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" class="align-middle" :key="user.uuid">
            <td>
              <router-link
                :to="{ name: 'manageUserRpc', params: { uuid: user.uuid } }"
              >
                {{ user.username }}
              </router-link>
            </td>
            <td>
              {{ user.email }}
              <b-button v-if="user.is_admin" class="btn clipboard-btn" @click="$_copyToClipboard(user.email)">
                <b-icon icon="clipboard"></b-icon>
              </b-button>
            </td>
            <td>
              <span v-if="!user.confirmed_at" class="text-danger">
                Unconfirmed
              </span>
              <span v-else-if="user.is_active" class="text-success">
                Enabled
              </span>
              <span v-else class="text-danger">
                Disabled
              </span>
            </td>
            <td>
              <span v-if="user.role.name === 'admin'" class="badge badge-soft-primary">
                Admin
              </span>
              <span v-else-if="user.role.name === 'operator'" class="badge badge-soft-info">
                Operator
              </span>
              <span v-else-if="user.role.name === 'reseller'" class="badge badge-soft-success">
                Reseller
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="meta.total_pages > 1" class="overflow-auto">
      <b-pagination-nav
        v-if="meta.total_count > 0"
        :link-gen="linkGen"
        v-model="currentPage"
        @change="changePage"
        :number-of-pages="meta.total_pages"
        use-router
      />
    </div>

    <p class="fs--1">{{ meta.total_count }} Users</p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import http from '@/services/http';
import debounce from 'lodash.debounce';
import copyToClipboard from '@/mixins/copy-to-clipboard';

export default {
  data() {
    return {
      perPage: 50,
      currentPage: 1,
      meta: {},
      users: [],
      searchUsersTerm: null,
      searchRole: null,
      statusFilter: null,
      isLoading: true,
      loadingHeight: 0,
    }
  },

  created() {
    this.onCreated();
  },

  mixins: [copyToClipboard],

  computed: {
    ...mapGetters('sessions', [
      'currentUser'
    ]),
    ...mapState({
      roles: state => state.roles.roles
    }),

    rolesDropdownOptions() {
      return this.roles.map(role => (
        { value: role.name, label: role.name.replace(/^\w/, c => c.toUpperCase()) }
      ));
    },

    findRoleOptionByValue() {
      return this.rolesDropdownOptions.find(option =>
        option.value === this.$route.query.users_role
      );
    },

    statusesDropdownOptions() {
      return [
        { value: 'is_confirmed=false', label: 'Unconfirmed' },
        { value: 'is_active=true', label: 'Enabled' },
        { value: 'is_active=false', label: 'Disabled' },
      ];
    },

    findStatusOptionByLabel() {
      return this.statusesDropdownOptions.find(option =>
        option.label === this.$route.query.users_status
      );
    },
  },

  watch: {
    searchUsersTerm: 'debouncedSearchUsers',
    searchRole: 'searchUsers',
    statusFilter: 'searchUsers',
  },

  methods: {
    async onCreated() {
      if (this.$route.query.users_page) {
        this.currentPage = parseInt(this.$route.query.users_page);
      }

      await this.$store.dispatch('roles/fetchRoles');

      if (this.$route.query.users_role) {
        this.searchRole = this.findRoleOptionByValue;
      }

      if (this.$route.query.users_status) {
        this.statusFilter = this.findStatusOptionByLabel;
      }

      if (!this.$route.query.users_role && !this.$route.query.users_status) {
        await this.fetchUsers();
      }

      if (this.meta.total_pages > 0 && this.currentPage > this.meta.total_pages) {
        await this.searchUsers();
      }
    },

    async fetchUsers() {
      try {
        this.loadingHeight = this.$refs.usersTable?.clientHeight;
        this.isLoading = true;

        let usersParams = {
          per: this.perPage,
          page: this.currentPage,
        }

        if (this.searchUsersTerm) {
          usersParams.username = this.searchUsersTerm
        }

        if (this.searchRole) {
          usersParams.role = this.searchRole.value
        }

        if (this.statusFilter) {
          const keyValue = this.statusFilter.value.split('=');
          usersParams[keyValue[0]] = keyValue[1];

          if (this.statusFilter.label === 'Enabled') {
            usersParams['is_confirmed'] = true;
          }
        }

        const response = await http.get('users', {
          params: usersParams
        });

        this.users = response.data.users;
        this.meta = response.data.meta;
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      }

      finally {
        this.isLoading = false;
      }
    },

    linkGen(pageNum) {
      let query = pageNum === 1 ? '?' : `?users_page=${pageNum}`;

      if (this.searchRole) {
        query += `&users_role=${this.searchRole.value}`;
      }

      if (this.statusFilter) {
        query += `&users_status=${this.statusFilter.label}`;
      }

      return query;
    },

    debouncedSearchUsers: debounce(function () {
      this.searchUsers();
    }, 600),

    async searchUsers() {
      this.goToFirstPage()
      await this.fetchUsers()
    },

    goToFirstPage() {
      let query = { users_page: this.currentPage = '1' };

      if (this.searchRole) {
        query.users_role = this.searchRole.value;
      }

      if (this.statusFilter) {
        query.users_status = this.statusFilter.label;
      }

      if (!_.isEqual(this.$route.query, query)) {
        this.$router.push({ name: this.$route.name, query: query });
      }
    },

    async changePage(newPage) {
      this.currentPage = newPage;
      await this.fetchUsers();
    },
  },
}
</script>

<style scoped>
  .btn.clipboard-btn {
    padding: 5px;
    color: #5e6e82;
    border: none;
    background-color: inherit;
  }

  .vs__dropdown-toggle {
    padding: 0 0 6px;
  }
</style>
