import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    isFetched: false,
    subscriptionTypes: []
  },

  getters: {
    all(state) {
      if (state.subscriptionTypes.length > 0) {
        return state.subscriptionTypes;
      }
    },
  },

  mutations: {
    SET_FETCHED(state) {
      state.isFetched = true;
    },

    SET_SUBSCRIPTION_TYPES(state, subscriptionTypes) {
      state.subscriptionTypes = subscriptionTypes;
    },

    ADD_SUBSCRIPTION_TYPE(state, subscriptionType) {
      state.subscriptionTypes.push(subscriptionType);
    },

    UPDATE_SUBSCRIPTION_TYPE(state, subscriptionType) {
      const index = state.subscriptionTypes.findIndex(st => st.uuid === subscriptionType.uuid);
      state.subscriptionTypes.splice(index, 1, subscriptionType);
    },

    RESET_STATE(state) {
      state.isFetched = false;
      state.subscriptionTypes = [];
    }
  },

  actions: {
    async createSubscriptionType({ commit, getters }, subscriptionTypeParams) {
      try {
        const response = await http.post('subscription_types', {
          subscription_type: subscriptionTypeParams
        });

        let cachedSubscriptionTypes = getters.all;

        if (cachedSubscriptionTypes) {
          commit('SET_SUBSCRIPTION_TYPES', cachedSubscriptionTypes.concat(response.data.subscription_type));
        } else {
          const subscriptionTypes = await http.get('subscription_types');
          commit('SET_SUBSCRIPTION_TYPES', subscriptionTypes.data.subscription_types);
        }

        return response;
      }

      catch (error) {
        return error;
      }
    },

    async fetchSubscriptionTypes({ commit, getters, state }) {
      try {
        let cachedSubscriptionTypes = getters.all;

        if (cachedSubscriptionTypes) {
          return cachedSubscriptionTypes;
        } else if (!state.isFetched) {
          commit('SET_FETCHED');
          const subscriptionTypes = await http.get('subscription_types');
          commit('SET_SUBSCRIPTION_TYPES', subscriptionTypes.data.subscription_types);

          return subscriptionTypes.data.subscription_types;
        }
      }

      catch (error) {
        console.log(error);
        return error;
      }
    },

    async fetchSubscriptionType({}, uuid) {
      return (await http.get(`subscription_types/${uuid}`)).data.subscription_type;
    }
  }
}
