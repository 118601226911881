import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    loadBalancers: []
  },

  mutations: {
    SET_LOAD_BALANCERS(state, loadBalancers) {
      state.loadBalancers = loadBalancers;
    },

    ADD_LOAD_BALANCER(state, loadBalancer) {
      state.loadBalancers = state.loadBalancers.concat(loadBalancer);
    },

    DELETE_LOAD_BALANCER(state, loadBalancer) {
      state.loadBalancers = state.loadBalancers.filter(lb => {
        return lb.uuid != loadBalancer.uuid;
      });
    },

    RESET_STATE(state) {
      state.loadBalancers = [];
    }
  },

  actions: {
    async getLoadBalancers({ commit }) {
      try {
        const response = await http.get('admin/load_balancers');
        commit('SET_LOAD_BALANCERS', response.data.load_balancers);
      }

      catch (error) {
        console.log(error);
      }
    },

    async createLoadBalancer({ commit }, loadBalancerParams) {
      try {
        const response = await http.post('admin/load_balancers', {
          load_balancer: loadBalancerParams
        });

        commit('ADD_LOAD_BALANCER', response.data.load_balancer);

        return response
      }

      catch (error) {
        console.log(error);
        return error.response
      }
    },

    async deleteLoadBalancer({ commit }, loadBalancer) {
      try {
        const response = await http.delete(`admin/load_balancers/${loadBalancer.uuid}`);

        commit('DELETE_LOAD_BALANCER', loadBalancer);

        return response
      }

      catch (error) {
        console.log(error);
        return error.response
      }
    }
  }
}
