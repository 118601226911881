<!-- CustomerServiceChat.vue -->
<template>
  <div class="chat-widget">
    <!-- Chat Toggle Button -->
    <button
      class="chat-toggle-button"
      @click="toggleChatWidget"
      :class="{ 'is-open': isOpen }"
    >
      <b-icon :icon="isOpen ? 'x' : 'chat-dots-fill'" font-scale="1.5"></b-icon>
      <span v-if="unreadCount > 0" class="unread-badge">{{ unreadCount }}</span>
    </button>

    <!-- Chat Window -->
    <transition name="slide">
      <div v-if="isOpen" class="chat-window">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">Customer Support Chat</h4>
            <b-badge v-if="isConnected" variant="success">Connected</b-badge>
            <b-badge v-else variant="danger">Disconnected</b-badge>
          </b-card-header>

          <div class="chat-messages" ref="chatBox" @scroll="handleScroll">
            <div v-if="isLoading" class="loading-container">
              <b-spinner variant="primary" label="Loading..."></b-spinner>
              <p class="mt-2 text-muted">Connecting to chat...</p>
            </div>
            <b-list-group v-else flush>
              <b-list-group-item v-for="message in messages" :key="message.id"
                :class="{'text-right': message.isUser}">
                <div :class="{'user-message': message.isUser, 'agent-message': !message.isUser}">
                  <strong>{{ message.isUser ? 'You' : formatUsername(message.senderName, message.isTritonEmployee) }}</strong>
                  <p class="mb-0">{{ message.text }}</p>
                  <small class="text-muted">{{ formatTime(message.timestamp) }}</small>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>

          <b-card-footer>
            <b-form @submit.prevent="sendMessage">
              <template v-if="!hasActiveChat">
                <b-form-textarea
                  v-model="newMessage"
                  placeholder="Please describe the issue that you'd like us to help with."
                  :disabled="isLoading"
                  rows="3"
                  max-rows="6"
                  class="mb-2"
                  autocomplete="off"
                ></b-form-textarea>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="!newMessage || isLoading"
                >
                  Start Chat
                </b-button>
              </template>
              <template v-else>
                <b-input-group>
                  <b-form-input
                    v-model="newMessage"
                    placeholder="Type your message..."
                    :disabled="!isConnected || isLoading"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button type="submit" variant="primary" :disabled="!isConnected || !newMessage || isLoading">
                      Send
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form>
          </b-card-footer>

          <div v-if="hasActiveChat" class="text-center p-2">
            <b-link @click="resolveAndCloseChat">Resolve and close chat</b-link>
          </div>
        </b-card>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import http from '@/services/http';

export default {
  name: 'CustomerServiceChat',

  data() {
    return {
      mattermostHost: 'http://localhost:8065/api/v4',
      messages: [],
      newMessage: '',
      isConnected: false,
      websocket: null,
      mattermostUserId: null,
      channelId: null,
      token: null,
      isOpen: false,
      unreadCount: 0,
      isScrolledToBottom: true,
      isInitialized: false,
      isLoading: false,
      messageTypesToIgnore: [
        'system_join_channel',
        'system_add_to_channel',
        'system_change_chan_privacy',
      ]
    }
  },

  beforeDestroy() {
    if (this.websocket) {
      this.websocket.close();
    }
  },

  computed: {
    ...mapGetters('sessions', ['currentUser']),

    account() {
      return this.currentUser.accounts[0]
    },

    hasActiveChat() {
      return this.currentUser.accounts[0].has_active_mattermost_chat;
      // return this.account.has_active_mattermost_chat;
    },

    mattermostHostname() {
      if (window.location.hostname === 'localhost') {
        return 'localhost:8065';
      } else if (window.location.hostname === 'customers-staging1.rpcpool.com') {
        return 'mattermost-staging.rpcpool.com';
      } else {
        return 'mattermost.rpcpool.com';
      }
    },

    mattermostHttpUrl() {
      if (window.location.hostname === 'localhost') {
        return 'http://' + this.mattermostHostname + '/api/v4';
      } else {
        return 'https://' + this.mattermostHostname + '/api/v4';
      }
    },

    mattermostWebSocketUrl() {
      if (window.location.hostname === 'localhost') {
        return 'ws://' + this.mattermostHostname + '/api/v4/websocket';
      } else {
        return 'wss://' + this.mattermostHostname + '/api/v4/websocket';
      }
    }
  },

  methods: {
    ...mapMutations('sessions', ['UPDATE_CURRENT_USER_ACCOUNT_CHAT']),

    formatUsername(username, isTritonEmployee) {
      return isTritonEmployee ? `${username} (Triton)` : username;
    },

    async resolveAndCloseChat() {
      try {
        await http.post(`mattermost_chats/accounts/${this.account.uuid}/resolve_chat`);
        this.UPDATE_CURRENT_USER_ACCOUNT_CHAT({ accountUuid: this.account.uuid, mattermostChatState: false });
        this.isOpen = false;
        this.$bvToast.toast('Chat resolved and closed successfully', {
          title: 'Success',
          variant: 'default'
        });
      } catch (error) {
        console.error('Failed to resolve chat:', error);
        this.$bvToast.toast('Failed to resolve chat. Please try again.', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },

    async toggleChatWidget() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.unreadCount = 0;
        if (!this.isInitialized) {
          this.isLoading = true;
          try {
            await this.initializeChat();
            await this.loadExistingMessages();
            this.isInitialized = true;
          } catch (error) {
            console.error('Failed to initialize chat:', error);
            this.$bvToast.toast('Failed to initialize chat. Please try again.', {
              title: 'Error',
              variant: 'danger'
            });
            this.isOpen = false;
            return;
          } finally {
            this.isLoading = false;
          }
        }
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },

    handleScroll() {
      const chatBox = this.$refs.chatBox;
      this.isScrolledToBottom = chatBox.scrollHeight - chatBox.scrollTop === chatBox.clientHeight;
    },

    async initializeChat() {
      try {
        const response = await http.post('mattermost_chats/init_chat', {
          account_uuid: this.account.uuid
        });
        const respData = response.data;
        this.channelId = respData.channel.id;
        this.token = respData.token;
        this.mattermostUserId = respData.user.id;

        this.setupWebSocket();

        this.isConnected = true;
      } catch (error) {
        console.error('Chat initialization failed:', error);
        this.$bvToast.toast('Failed to connect to customer service. Please try again later.', {
          title: 'Connection Error',
          variant: 'danger'
        });
        throw error;
      }
    },

    setupWebSocket() {
      const url =
      this.websocket = new WebSocket(this.mattermostWebSocketUrl);

      this.websocket.onopen = () => {
        this.websocket.send(JSON.stringify({
          seq: 1,
          action: 'authentication_challenge',
          data: { token: this.token }
        }));
      };

      this.websocket.onmessage = async (event) => {
        const data = JSON.parse(event.data);
        if (data.event === 'posted') {
          const post = JSON.parse(data.data.post);

          if (post.user_id !== this.mattermostUserId && post.channel_id === this.channelId) {
            // Fetch user info for the sender
            const userMap = await this.fetchUsers();
            const userInfo = userMap[post.user_id] || { username: data.data.sender_name || 'Unknown User', isTritonEmployee: false };

            this.messages.push({
              id: post.id,
              text: post.message,
              isUser: false,
              senderName: userInfo.username,
              isTritonEmployee: userInfo.isTritonEmployee,
              timestamp: new Date(post.create_at)
            });

            if (!this.isOpen || !this.isScrolledToBottom) {
              this.unreadCount++;
            }

            if (this.isScrolledToBottom) {
              this.scrollToBottom();
            }
          }
        }
      };

      this.websocket.onclose = () => {
        this.isConnected = false;
      };
    },

    async sendMessage() {
      if (!this.newMessage.trim()) return;

      try {
        if (!this.hasActiveChat) {
          await http.post(`mattermost_chats/accounts/${this.account.uuid}/start_chat`);
          this.UPDATE_CURRENT_USER_ACCOUNT_CHAT({ accountUuid: this.account.uuid, mattermostChatState: true });
        }

        const response = await fetch(`${this.mattermostHttpUrl}/posts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}` // TODO: We shouldn't expose this
          },
          body: JSON.stringify({
            channel_id: this.channelId,
            message: this.newMessage
          })
        });

        if (!response.ok) throw new Error('Failed to send message');

        const messageData = await response.json();
        this.messages.push({
          id: messageData.id,
          text: this.newMessage,
          isUser: true,
          timestamp: new Date()
        });

        this.newMessage = '';
        this.scrollToBottom();
      } catch (error) {
        console.error('Message send failed:', error);
        this.$bvToast.toast('Failed to send message. Please try again.', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },

    async fetchUsers() {
      try {
        const response = await fetch(`${this.mattermostHttpUrl}/users`, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch users');

        const users = await response.json();
        return users.reduce((acc, user) => {
          acc[user.id] = {
            username: user.username,
            isTritonEmployee: user.props?.is_triton_employee === 'true'
          };
          return acc;
        }, {});
      } catch (error) {
        console.error('Failed to fetch users:', error);
        return {};
      }
    },

    async loadExistingMessages() {
      try {
        const [messagesResponse, userMap] = await Promise.all([
          fetch(`${this.mattermostHttpUrl}/channels/${this.channelId}/posts`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          }),
          this.fetchUsers()
        ]);

        if (!messagesResponse.ok) throw new Error('Failed to load messages');

        const data = await messagesResponse.json();
        const posts = Object.values(data.posts)
          .sort((a, b) => a.create_at - b.create_at)
          .filter(post => post.type !== 'system_join_channel' && post.type !== 'system_add_to_channel' && post.type !== 'system_change_chan_privacy')
          .map(post => {
            const userInfo = userMap[post.user_id] || {
              username: post.props?.override_username || 'Unknown User',
              isTritonEmployee: false
            };
            return {
              id: post.id,
              text: post.message,
              isUser: post.user_id === this.mattermostUserId,
              senderName: userInfo.username,
              isTritonEmployee: userInfo.isTritonEmployee,
              timestamp: new Date(post.create_at)
            };
          });

        this.messages = posts;
        this.scrollToBottom();
      } catch (error) {
        console.error('Failed to load messages:', error);
        this.$bvToast.toast('Failed to load message history', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const chatBox = this.$refs.chatBox;
        chatBox.scrollTop = chatBox.scrollHeight;
        this.isScrolledToBottom = true;
      });
    },

    formatTime(timestamp) {
      return new Date(timestamp).toLocaleTimeString();
    }
  }
}
</script>

<style scoped>
.chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-toggle-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: rgba(0, 123, 255, 0.9);
  color: white;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1001;
}

.chat-toggle-button:hover {
  transform: scale(1.1);
  background-color: rgba(0, 86, 179, 0.95);
}

.chat-toggle-button.is-open {
  background-color: rgba(220, 53, 69, 0.9);
}

.unread-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}

.chat-window {
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 350px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.chat-messages {
  height: 350px;
  overflow-y: auto;
  padding: 1rem;
  scroll-behavior: smooth;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.user-message {
  background-color: #007bff;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-left: 25%;
}

.agent-message {
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-right: 25%;
}

/* Slide transition */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
